@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 1.5s ease-out forwards;
}

.html, body {
  height: 100%;
  margin: 0;
  background-color: #194569;
}

.site-container{
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #194569;
  /*background: linear-gradient(to bottom, #003459 0%, #007EA7 25%);*/
  z-index: -1;
}

.title-box{
  display: flex;
  justify-content: center;
  padding-top: 50px;
  padding-left: 45px;
  padding-right: 45px;
  flex-wrap: wrap;
}

.title-text{
  color: #FFFFFF;
  font-size: 60px;
  font-family: 'MuseoModerno', cursive;;
  text-align: center;
}


.centered-section{
  display: flex;
  justify-content: center;
  flex-wrap: wrap; /* Allows items to wrap onto the next line */
  margin-bottom: 20px;
}

.links-container {
  width: 250px;
  display: flex;
  justify-content: space-evenly;
}

.links-container .content-link {
  color: #91aec4;
  transition: color 0.2s ease-in-out, transform 0.2s ease-in-out; /* Add transform to the transition */
}

.links-container .content-link:hover {
  color: #FFFFFF; /* Hover color */
  transform: scale(1.1); /* Add scaling effect */
}

.content-link i {
  font-size: 27px; /* Adjusts the size of the icons */
}

.about-me-section{
  max-width: 750px;
}
.about-me-container{
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 20px;
}

.body-text{
  color: #FFFFFF;
  font-family: 'Roboto', sans-serif; 
  font-size: 17px;
  
}

.link-text {
  color: #91aec4; /* Text color */
  text-decoration: none; /* Removes the default underline */
  font-weight: 600; /* Your specified font weight */
  display: inline-block; /* Ensures the pseudo-element aligns correctly */
  position: relative; /* Necessary for positioning the pseudo-element */
  overflow: hidden; /* Ensures the underline doesn't extend beyond the text */
  transition: color 0.25s ease-in-out; /* Smooth transition for color change */
  margin-bottom: -7px;
}

.link-text::after {
  content: '';
  position: absolute;
  left: 0;
  right: 100%; /* Starts with no visible underline */
  bottom: 0;
  height: 2px; /* Height of the underline */
  background-color: #FFFFFF; /* Color of the underline, now matches hover text color */
  transition: right 0.25s ease-in-out; /* Matches the timing of the text color transition */
}

.link-text:hover::after {
  right: 0; /* Extends the underline across the element on hover */
}

.link-text:hover{
  color: #FFFFFF;
  transition: color 0.2s ease-in-out;
}

.header-section{
  display: flex;
  justify-content: start;
  padding-bottom: 20px;
}
.header-text{
  
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-weight: 700;
  color: #FFFFFF ;
}

.projects-section{
  max-width: 750px; /* Sets the maximum width */
  width: 100%; /* Allows the width to shrink with the viewport */
  display: flex;
  flex-direction: column; /* Aligns children vertically */
  justify-content: flex-start; /* Aligns children at the start vertically */
  padding-left: 50px;
  padding-right: 50px;
  
}

.project-card {
  max-width: 650px; /* Sets the maximum width of the box */
  width: 100%;
  border: 3px solid #91aec4; /* Adds a border with a light grey color */
  border-radius: 10px; /* Makes the borders rounded */
  padding: 20px; /* Adds padding inside the box */
  
  margin: 10px auto; /* Centers the box horizontally and adds vertical spacing */
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow around the box */
  background: #FFFFFF; /* Sets a white background */
  transition: border 0.3s ease-in-out, box-shadow 0.3s ease-in-out; /* Smooth transition for border, shadow, and background */
}

.project-card:hover {
  border: 3px solid #FFFFFF; /* Changes border color on hover */
  box-shadow: 0 0 16px rgba(255, 255, 255, 0.5); /* Adds a white, more prominent shadow around the box on hover */
}

.project-description{
  background-color: #194569;
  border-radius: 10px;
  padding: 15px;
}


